define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-features", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseAiFeatures extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    async model() {
      return this.store.findAll("ai-feature");
    }
  }
  _exports.default = AdminPluginsShowDiscourseAiFeatures;
});