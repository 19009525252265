define("discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-features/edit", ["exports", "ember-route-template", "discourse/components/back-button", "admin/components/site-setting", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _emberRouteTemplate, _backButton, _siteSetting, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)((0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <BackButton
        @route="adminPlugins.show.discourse-ai-features"
        @label="discourse_ai.features.back"
      />
      <section class="ai-feature-editor__header">
        <h2>{{@model.name}}</h2>
        <p>{{@model.description}}</p>
      </section>
  
      <section class="ai-feature-editor">
        {{#each @model.feature_settings as |setting|}}
          <div>
            <SiteSettingComponent @setting={{setting}} />
          </div>
        {{/each}}
      </section>
    
  */
  {
    "id": "vNpIO8W7",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@route\",\"@label\"],[\"adminPlugins.show.discourse-ai-features\",\"discourse_ai.features.back\"]],null],[1,\"\\n    \"],[10,\"section\"],[14,0,\"ai-feature-editor__header\"],[12],[1,\"\\n      \"],[10,\"h2\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n      \"],[10,2],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"section\"],[14,0,\"ai-feature-editor\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"feature_settings\"]]],null]],null],null,[[[1,\"        \"],[10,0],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@setting\"],[[30,2]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@model\",\"setting\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-features/edit.js",
    "scope": () => [_backButton.default, _siteSetting.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, undefined)));
});