define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-features-edit", ["exports", "discourse/lib/ajax", "discourse/routes/discourse", "admin/models/site-setting"], function (_exports, _ajax, _discourse, _siteSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseAiFeaturesEdit extends _discourse.default {
    async model(params) {
      const allFeatures = this.modelFor("adminPlugins.show.discourse-ai-features");
      const id = parseInt(params.id, 10);
      const currentFeature = allFeatures.find(feature => feature.id === id);
      const {
        site_settings
      } = await (0, _ajax.ajax)("/admin/config/site_settings.json", {
        data: {
          filter_area: `ai-features/${currentFeature.ref}`,
          plugin: "discourse-ai",
          category: "discourse_ai"
        }
      });
      currentFeature.feature_settings = site_settings.map(setting => _siteSetting.default.create(setting));
      return currentFeature;
    }
  }
  _exports.default = AdminPluginsShowDiscourseAiFeaturesEdit;
});